<template>
  <div class="goodsDetail">
    <el-form label-width="120px" label-position="left">
      <el-form-item label="参数模板">
        <el-select value="" placeholder="请选择参数模板" @change="selectTemplateHandle($event)">
          <el-option v-for="item in parameterTemplateList" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
      </el-form-item>
      <span class="parametersTips">参数可以添加自定义参数，也可以通过参数模板批量设置参数</span>
      <div class="parametersTable">
        <el-table :data="goodsDetail.param" border style="width: 741px">
          <el-table-column label="参数名称" prop="name" width="180">
            <template slot-scope="scope">
              <el-input v-model="scope.row.label" placeholder="请输入参数名称" v-if="!scope.row.id"></el-input>
              <span v-else>{{scope.row.label}}</span>
            </template>
          </el-table-column>
          <el-table-column label="参数值" prop="value" width="460">
            <template slot-scope="scope">
              <el-input v-model="scope.row.value[0].value" placeholder="请输入参数值" v-if="scope.row.type == 'input'"></el-input>
              <template v-if="scope.row.type == 'checkbox'">
                <el-checkbox v-for="(item,index) in scope.row.value" :key="index" v-model="item.select" :true-label="1" :false-label="0">{{item.name}}</el-checkbox>
              </template>
              <el-radio-group v-if="scope.row.type == 'radio'" v-model="scope.row.radioValue" @change="radioChangeHandle($event,scope.$index)">
                <el-radio v-for="(item,index) in scope.row.value" :key="index" :label="item.name">{{item.name}}</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="goodsDetail.param.splice(scope.$index,1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableFuc">
          <el-button size="mini" type="primary" @click="addParameter">添加参数</el-button>
          <el-button size="mini" @click="dialogVisible = true">批量导入</el-button>
<!--          <el-button size="mini" @click="upLoadParameter">上传导入</el-button>-->
        </div>
      </div>
      <el-form-item label="内容描述">
        <tinymce v-model="goodsDetail.descr" placeholder="请输入内容" :height="600" />
      </el-form-item>
    </el-form>
    <el-dialog
        title="批量导入"
        :visible.sync="dialogVisible"
        width="30%"
        @close="closeDialog"
    >
      <el-input type="textarea" :rows="5" v-model="parameterText" :placeholder="placeholderText"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitParameter">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {apiGetGoodsParamList} from '@/request/api'
export default {
  name: "goodsDetail",
  props:{
    goodsType: {
      type: String,
      default: ''
    },
    goodsDetail:{
      type: Object,
      default: {
        param:[],
        descr:''
      }
    }
  },
  data () {
    return {
      parameterTemplateList: [],
      dialogVisible:false,
      parameterText:'',
      placeholderText:'参数名:参数值\n参数名:参数值\n参数名:参数值',
    }
  },
  watch:{
    goodsType:{
      handler(val) {
        this.getParameterTemplateList()
      }
    },
    goodsDetail: {
      handler(val) {
        this.$emit('handleChange', this.goodsDetail)
      }
    }
  },
  created() {
    this.getParameterTemplateList()
  },
  methods:{
    upLoadParameter() {
    },
    submitParameter() {
      var newParameterList = this.parameterText.split('\n')
      newParameterList.forEach(item => {
        var obj = {
          id: 0,
          label: item.split(':')[0],
          value: [
            {
              id:0,
              name:'',
              select: 0,
              value: item.split(':')[1],
            }
          ],
          type: 'input',
        }
        this.goodsDetail.param.push(obj)
      })
      this.closeDialog()
    },
    closeDialog() {
      this.parameterText = ''
      this.dialogVisible = false
    },
    addParameter(){
      this.goodsDetail.param.push({
        id: 0,
        label: '',
        value: [
          {
            id:0,
            name:'',
            select: 0,
            value: '',
          }
        ],
        type: 'input',
      })
    },
    selectTemplateHandle(val){
      var templateItem = JSON.parse(JSON.stringify(this.parameterTemplateList.find(item => item.id == val)))
      if(templateItem.type == 'radio'){
        templateItem.radioValue = ''
        templateItem.value.forEach(item => {
          if(item.select == 1){
            templateItem.radioValue = item.name
          }
        })
      }
      this.goodsDetail.param.push(templateItem)
    },
    getParameterTemplateList(){
      apiGetGoodsParamList({id:this.goodsType}).then(res => {
        if(res.code == 200){
          this.parameterTemplateList = res.data
        }
      })
    },
    radioChangeHandle(val,index){
      this.goodsDetail.param[index].value.forEach(item => {
        item.select = 0
        if (item.name == val) {
          item.select = 1
        }
      })
    }
  }
}
</script>

<style scoped>
.goodsDetail {
  width: 100%;
  padding-top: 0.6rem;
}
.parametersTips{
  margin-left: 120px;
  margin-top: 1rem;
}
.parametersTable {
  margin-left: 120px;
  margin-top: 1rem;
}
.tableFuc {
  margin-top: 1rem;
}

</style>