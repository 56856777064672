<template>
  <div class="purchaseLimits">
    <div style="display: flex;align-items: center;cursor: pointer"  @click="showBox = !showBox">
      <h2 class="purchaseLimits-title">销售限制</h2>
      <i style="margin-left: 7px;margin-top: 2px" :class="!showBox?'el-icon-arrow-down':'el-icon-arrow-up'"/>
    </div>
    <div class="box" :style="showBox?'height: 212px;':'height: 0;'">
      <el-radio-group v-model="limitObj.type" style="width: 100%">
        <el-radio :label="'FOLLOW_SYSTEM'">跟随系统设置</el-radio>
        <div class="radioItem">
          <el-radio :label="'NOT_ALLOW_AREA'">不售卖地区</el-radio>
          <el-select v-model="limitObj.area_id" multiple placeholder="请选择" :disabled="limitObj.type != 'NOT_ALLOW_AREA'" style="flex-grow: 1">
            <el-option
                v-for="item in areaLabelList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="radioItem">
          <el-radio :label="'ALLOW_AREA'">只售卖地区</el-radio>
          <el-select v-model="limitObj.area_id" multiple placeholder="请选择" :disabled="limitObj.type != 'ALLOW_AREA'" style="flex-grow: 1">
            <el-option
                v-for="item in areaLabelList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </el-radio-group>
      <div class="limitItem">
        <span>限购 </span>
        <el-input-number :min="0" size="small"  v-model="limitObj.purchase_num_limit"></el-input-number>
        <span> 件 （0表示不限购）</span>
      </div>
      <div class="limitItem">
        <span>起购 </span>
        <el-input-number :min="1" size="small"  v-model="limitObj.purchase_min_limit"></el-input-number>
        <span> 件 （当前系统设置，超过库存将无法购买）</span>
      </div>
    </div>

  </div>
</template>

<script>
import {apiGetAreaList} from '@/request/api'
export default {
  name: "purchaseLimits",
  watch: {
    limitObj: {
      handler(val) {
        this.$emit('handleChange', val)
      },
      deep: true
    }
  },
  props:{
    limitObj: {
      type: Object,
      default: {
        type: 'FOLLOW_SYSTEM',
        area_id: [],
        purchase_num_limit: 0,
        purchase_min_limit: 1
      }
    }
  },
  data() {
    return {
      showBox: false,
      areaLabelList:[],
    };
  },
  created() {
    this.getAreaList()
  },
  methods: {
    getAreaList() {
      apiGetAreaList().then(res => {
        if(res.code == 200){
          this.areaLabelList = res.data
        }
      })
    },
  }
}
</script>

<style scoped>
.purchaseLimits {
  width: 100%;
  padding-top: 0.6rem;
}
.radioItem{
  margin-top: 0.6rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.limitItem{
  margin-top: 1rem;
  margin-left: 1.5rem;
}
.box {
  overflow: hidden;
  transform-origin: top;
  transition: all 0.5s;
}
</style>