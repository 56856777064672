<template>
  <div class="logistic">
    <div style="display: flex;align-items: center;cursor: pointer"  @click="showBox = !showBox">
      <h2 class="logistic-title">配送信息</h2>
      <i style="margin-left: 7px;margin-top: 2px" :class="!showBox?'el-icon-arrow-down':'el-icon-arrow-up'"/>
    </div>
      <div class="box" :style="showBox?logistic.type == 'UNIT_FIXED_PRICE' || logistic.type == 'FIXED_PRICE' || logistic.type == 'SHIPPING_TEMPLATE'?'height: 115px;':'height: 40px;':'height: 0;'">
      <el-form label-width="120px" label-position="left">
        <el-form-item label="配送政策">
          <el-radio-group v-model="logistic.type">
            <el-radio v-for="(item,index) in logisticList" :key="index" :label="item.index">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row :gutter="20" v-if="logistic.type == 'UNIT_FIXED_PRICE' || logistic.type == 'FIXED_PRICE'">
          <el-col :span="12">
            <el-form-item label="运费">
              <el-input v-model="logistic.price" type="number" :min="0" placeholder="请输入费用"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="logistic.type == 'SHIPPING_TEMPLATE'">
          <el-col :span="12">
            <el-form-item label="运费模板">
              <el-select v-model="logistic.template_id" filterable >
                <el-option v-for="item in templateList" :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
              <el-button style="margin-left: 20px" type="text" @click="toEidtModel">编辑模板@TODO</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

  </div>

</template>

<script>
import {apiGetFreightList} from '@/request/api'
export default {
  name: "logistic",
  watch: {
    logistic: {
      handler(val) {
        this.$emit('handleChange', val)
      },
      deep: true
    }
  },
  props:{
    logistic: {
      type: Object,
      default() {
        return {
          price: 0,
          template_id: '',
          type: 'FOLLOW_SYSTEM'
        }
      }
    },
  },
  data () {
    return {
      showBox: false,
      logisticList: [
        {
          label:'跟随系统设置',
          index:'FOLLOW_SYSTEM'
        },
        {
          label:'单间固定费用',
          index:'UNIT_FIXED_PRICE'
        },
        {
          label:'任意件固定费用',
          index:'FIXED_PRICE'
        },
        {
          label: '包邮',
          index: 'FREE_SHIPPING',
        },
        {
          label:'运费模板',
          index:'SHIPPING_TEMPLATE'
        },
      ],
      templateList: [],
    }
  },
  created() {
    this.getTemplateList()
  },
  methods: {
    getTemplateList() {
      apiGetFreightList().then(res => {
        if (res.code == 200) {
          this.templateList = res.data
        }
      })
    },
    toEidtModel(){
      alert('@TODO')
    }
  },
}
</script>

<style scoped>
.logistic {
  width: 100%;
  padding-top: 0.6rem;
}
.el-form-item{
  margin-top: 10px;
}
.box {
  overflow: hidden;
  transform-origin: top;
  transition: all 0.5s;
}

</style>