<template>
<div>
  <div class="dragView" >
    <el-tooltip content="可拖动排序" placement="left-top" hide-after="1000" effect="light">
      <draggable v-model="picList" style="display: flex;flex-wrap: wrap">
        <div v-for="(item,index) in picList" :key="item.url" class="picItem">
          <i class="el-icon-star-off firstLabel" v-if="index == 0"></i>
          <el-button v-if="index > 0" class="setFirst" type="primary" icon="el-icon-star-off" size="mini" circle @click="setFirst(index)"/>
          <el-button class="delBtn" type="danger" icon="el-icon-delete" size="mini" circle @click="picList.splice(index,1)"/>
          <el-image :src="item.origin" :key="item.id" style="width: 100px; height: 100px;border-radius: 10px" :preview-src-list="[item.origin]" fit="contain"/>
        </div>
      </draggable>
    </el-tooltip>

    <el-button icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px" @click="showSelecter = true"/>

  </div>
  <fileSelect :visible.sync="showSelecter" title="选择商品图片" state="image" multiple="1" @confirm="showSelecterSubmit"></fileSelect>
</div>


</template>

<script>
import draggable from 'vuedraggable'
import fileSelect  from "@/views/components/fileSelect";
export default {
  name: "dragablePicList",
  data() {
    return {
      showSelecter: false,
      picList:[]
    }
  },
  components: {
    draggable,
    fileSelect
  },
  props: {
    picList: {
      type: Array,
      default: []
    }
  },
  watch: {
    picList: {
      handler: function (newVal, oldVal) {
        this.$emit('change', this.picList)
      },
      deep: true
    }
  },
  methods:{
    showSelecterSubmit(data){
      console.log(data)
      this.picList=[...this.picList,...data]
      this.showSelecter = false
    },
    setFirst(index){
      let temp = this.picList[index]
      this.picList.splice(index,1)
      this.picList.unshift(temp)
    }
  }
}
</script>

<style lang="scss" scoped>
.dragView {
  height: 120px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .picItem {
    width: 100px;
    height: 100px;
    margin: 5px;
    border-radius: 10px;
    position: relative;
    flex-shrink: 0;
    .delBtn {
      display: none;
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 20;
    }
    .setFirst {
      display: none;
      position: absolute;
      top: -10px;
      right: 20px;
      z-index: 20;
    }
    .firstLabel {
      position: absolute;
      font-size: 24px;
      color: #006eff;
      top: -8px;
      left: -8px;
      z-index: 20;
    }
  }
  .picItem:hover {
    cursor: move;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.25);
    .delBtn,.setFirst {
      display: block;
    }
  }
}
</style>