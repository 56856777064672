<template>
  <div class="sku">
    <h2>商品价格</h2>
    <el-form label-width="120px" label-position="left">
      <el-form-item label="启用多规格">
        <el-switch v-model="goodsSku.type" active-value="MULTIPLE_PRICE" inactive-value="SINGAL_PRICE" @change="skuTypeChangeHandle"></el-switch>
      </el-form-item>
      <template v-if="goodsSku.type == 'SINGAL_PRICE'">
        <el-form-item label="SKU">
          <el-input v-model="goodsSku.sku[0].skuId" placeholder="请输入SKU"></el-input>
        </el-form-item>
        <el-form-item label="销售价">
          <el-input-number v-model="goodsSku.sku[0].price" :min="0" :max="999999" :step="0.01"/>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
        <el-form-item label="划线价">
          <el-input-number v-model="goodsSku.sku[0].marketPrice" :min="0" :max="999999" :step="0.01"/>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
        <el-form-item label="成本价">
          <el-input-number v-model="goodsSku.sku[0].cost" :min="0" :max="999999" :step="0.01"/>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
        <el-form-item label="重量">
          <el-input-number v-model="goodsSku.sku[0].weight" :min="0" :max="999999" :step="0.01"/>
          <span style="margin-left: 10px">g</span>
        </el-form-item>
        <el-form-item label="体积">
          <el-input-number v-model="goodsSku.sku[0].volume" :min="0" :max="999999" :step="0.01"/>
          <span style="margin-left: 10px">cm³</span>
        </el-form-item>
        <el-form-item label="商品库存">
          <el-input-number v-model="goodsSku.sku[0].stock" :min="0" :max="999999" :step="1"/>
          <span style="margin-left: 10px">件</span>
          <span style="margin-left: 5px">(0件为无限库存)</span>
        </el-form-item>
        <el-form-item label="库存预警">
          <el-input-number v-model="goodsSku.sku[0].stockWarning" :min="0" :max="999999" :step="1"/>
          <span style="margin-left: 10px">件</span>
          <span style="margin-left: 5px">(0件为不进行预警)</span>
        </el-form-item>
        <el-form-item label="包装数量">
          <el-input-number v-model="goodsSku.sku[0].packageNum" :min="0" :max="999999" :step="1"/>
        </el-form-item>
        <el-form-item label="包装单位">
          <el-input v-model="goodsSku.sku[0].packageUnit" />
        </el-form-item>

      </template>
      <skuMore :goodsType="goodsType" :sku="goodsSku" v-else @change="skuChange"/>

    </el-form>
  </div>
</template>

<script>
import skuMore from './skuMore.vue';
export default {
  name: "index",
  components: {
    skuMore
  },
  watch: {
    goodsSku: {
      handler(val) {
        this.$emit('handleChange', val);
      },
      deep: true
    },
  },
  props:{
    goodsSku: {
      type: Object,
      default: () => {
        return {
          type: "SINGAL_PRICE",
          sku:[
            {
              cost:0,
              price:9999,
              stock:0,
              volume:'',
              weight:0,
              marketPrice:0,
              stockWarning:0,
              skuId:'',
              packageNum:0,
              packageUnit:''
            }
          ],
          skuObj:[]
        }
      }
    },
    goodsType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    skuChange(data) {
      this.goodsSku.sku = data.goodsSku;
      this.goodsSku.skuObj = data.skuObj;
    },
    skuTypeChangeHandle(val) {
      if (val == 'SINGAL_PRICE') {
        this.goodsSku.sku = [
          {
            cost: 0,
            price: 0,
            stock: 0,
            volume: '',
            weight: 0,
            marketPrice: 0,
            stockWarning: 0,
            packageNum:0,
            packageUnit:''
          }
        ]
        this.goodsSku.skuObj = []
      }
      if (val == 'MULTIPLE_PRICE') {
        this.goodsSku.sku = []
        this.goodsSku.skuObj = []
      }
    }
  }
}
</script>

<style scoped>

</style>
