import { render, staticRenderFns } from "./dragableVideoList.vue?vue&type=template&id=3a56a530&scoped=true&"
import script from "./dragableVideoList.vue?vue&type=script&lang=js&"
export * from "./dragableVideoList.vue?vue&type=script&lang=js&"
import style0 from "./dragableVideoList.vue?vue&type=style&index=0&id=3a56a530&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a56a530",
  null
  
)

export default component.exports