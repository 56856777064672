import { render, staticRenderFns } from "./goodsOtherInfo.vue?vue&type=template&id=134a9b20&scoped=true&"
import script from "./goodsOtherInfo.vue?vue&type=script&lang=js&"
export * from "./goodsOtherInfo.vue?vue&type=script&lang=js&"
import style0 from "./goodsOtherInfo.vue?vue&type=style&index=0&id=134a9b20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134a9b20",
  null
  
)

export default component.exports