<template>
  <el-form label-width="120px" label-position="left">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="商品名称" prop="name">
          <el-input v-model="goods.name" placeholder="请输入商品名称" clearable></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="SLUG" prop="slug">
          <el-input v-model="goods.slug" placeholder="请输入SLUG"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="促销语" prop="promotion">
      <el-input v-model="goods.promotion" placeholder="请输入促销语"></el-input>
    </el-form-item>
    <el-form-item label="关键字（标签）" prop="tag">
      <el-select v-model="goods.tag" placeholder="请选择关键字（标签）" multiple filterable allow-create default-first-option style="width: 100%">
        <el-option v-for="item in tagList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="商品相册">
      <dragablePicList :isShow="true" :picList="goods.gallery" @change="dragablePicListHandle"></dragablePicList>
    </el-form-item>
    <el-form-item label="商品视频" prop="">
      <dragableVideoList :isShow="true" :videoList="goods.video" @change="dragableVideoListHandle"></dragableVideoList>
    </el-form-item>
<!--    <el-form-item label="供应商" prop="supply_id">-->
<!--      <el-select v-model="goods.supply_id" placeholder="请选择供应商">-->
<!--        <el-option v-for="item in providerList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--      </el-select>-->
<!--    </el-form-item>-->
    <el-form-item label="商品分类" prop="category_id" >
      <el-cascader v-model="goods.category_id" :options="goodsCategoryList" placeholder="请选择商品分类" :show-all-levels="false" :props="{value:'id',label:'name',emitPath: false,}"></el-cascader>
    </el-form-item>
<!--    <el-form-item label="店铺分类" prop="shop_category_id">-->
<!--      <el-select v-model="goods.shop_category_id" placeholder="请选择店铺分类">-->
<!--        <el-option v-for="item in shopCategoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--      </el-select>-->
<!--    </el-form-item>-->
    <el-form-item label="品牌" prop="brand_id">
      <el-select v-model="goods.brand_id" placeholder="请选择品牌">
        <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="商品类型" prop="goods_type">
      <el-select v-model="goods.goods_type" placeholder="请选择类型">
        <el-option v-for="item in goodsTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="热门" prop="is_hot">
      <el-switch v-model="goods.is_hot" :active-value="1" :inactive-value="0"></el-switch>
    </el-form-item>
    <el-form-item label="新旧程度" prop="condition_id">
      <el-select v-model="goods.condition_id">
        <el-option v-for="item in goodsCondition" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="生产日期" prop="date_code">
      <el-input v-model="goods.date_code"></el-input>
    </el-form-item>
    <el-form-item label="封装工艺" prop="package">
      <el-input v-model="goods.package"></el-input>
    </el-form-item>
    <el-form-item label="Datasheet" >
      <el-input v-model="goods.datasheet"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import {apiGetGoodsOptions,apiGetGoodsParams} from '@/request/api'
import dragableVideoList from "@/views/goods/components/dragableVideoList";
import dragablePicList from "@/views/goods/components/dragablePicList";
export default {
  name: "goodsBaseInfo",
  components: {
    dragablePicList,
    dragableVideoList
  },
  watch: {
    goods: {
      handler(val) {
        this.$emit("handleChange", val);
      },
      deep: true
    }
  },
  props:{
    goods: {
      type: Object,
      default: () => {
        return {
          name: "",
          slug: "",
          promotion: "",
          tag: [],
          supply_id: 0,
          category_id: "",
          shop_category_id: 0,
          brand_id: "",
          gallery: [],
          video: [],
          goods_type:"",
          is_hot:0,
          condition_id:null,
          date_code:'',
          package:''
        };
      }
    },
  },
  data(){
    return {
      tagList: [],
      providerList: [],
      goodsCategoryList: [],
      shopCategoryList: [],
      brandList: [],
      goodsTypeList: [],
      goodsCondition: []
    }
  },
  created() {
    this.getOption()
    this.getParams()
  },
  methods:{
    dragablePicListHandle(data){
      console.log(data)
      this.goods.gallery = data;
    },
    dragableVideoListHandle(data){
      console.log(data)
      this.goods.video = data;
    },
    getOption(){
      apiGetGoodsOptions().then(res => {
        if(res.code == 200){
          this.brandList = res.data.brandCate;
          this.goodsCategoryList = res.data.goodsCate;
          this.shopCategoryList = res.data.shopCate;
          this.goodsTypeList = res.data.goodsTypeCate;
          this.providerList = res.data.provider;
        }
      })
    },
    getParams () {
      apiGetGoodsParams().then(res => {
        if(res.code == 200) {
          this.goodsCondition = res.data.condition
        }
      })
    }
  },

}
</script>

<style scoped>
.el-form-item{
  margin-top: 10px;
}
</style>
