import { render, staticRenderFns } from "./goodsOptimization.vue?vue&type=template&id=338ca1cb&scoped=true&"
import script from "./goodsOptimization.vue?vue&type=script&lang=js&"
export * from "./goodsOptimization.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338ca1cb",
  null
  
)

export default component.exports