<template>
    <div class="sku-more">
        <el-form label-width="120px" label-position="left">
          <el-form-item v-for="(item,index) in skuObj" :key="index" :label="'规格'+(index + 1)">
            <el-select placeholder="请选择" v-model="item.id" @change="skuSelecthandle($event,index)">
              <el-option v-for="(skuItem,index) in skuList" :key="index" :value="skuItem.id" :label="skuItem.label"></el-option>
            </el-select>
            <i class="el-icon-error delSkuBtn" @click="skuObj.splice(index,1)"/>
            <div style="margin-top: 5px">
              <el-checkbox size="mini" v-for="v in item.value" :label="v.label" :key="index + v.id" v-model="v.isChoosed" border/>
            </div>
            <el-button v-show="!item.showAdd" @click="showAdd(index)" type="text" icon="el-icon-plus">添加规格值</el-button>
            <div v-show="item.showAdd" style="margin-top: 10px">
              <el-input v-model="item.addValue" placeholder="请输入规格值" style="width: 200px;margin-right: 10px;"></el-input>
              <el-button @click="addValue(index)">添加</el-button>
              <el-button @click="closeAdd(index)">取消</el-button>
            </div>
          </el-form-item>
          <el-button style="margin-left: 120px" @click="addSku">添加规格</el-button>
          <el-row :gutter="20" >
            <el-col :span="12" >
              <el-form-item label="批量操作">
                <div class="setValueFuc" v-if="setType.length == 0">
                  <el-button type="text" @click="setType = 'subTitle'">副标题</el-button>
                  <el-button type="text" @click="setType = 'price'">销售价</el-button>
                  <el-button type="text" @click="setType = 'marketPrice'">划线价</el-button>
                  <el-button type="text" @click="setType = 'cost'">成本价</el-button>
                  <el-button type="text" @click="setType = 'stock'">库存</el-button>
                  <el-button type="text" @click="setType = 'stockWarning'">库存预警</el-button>
                  <el-button type="text" @click="setType = 'weight'">重量(g)</el-button>
                  <el-button type="text" @click="setType = 'volume'">体积(cm³)</el-button>
                </div>
                <div class="setValueFuc" v-if="setType.length != 0"  style="display: flex;align-items: center">
                  <el-input v-model="setValue" v-if="setType == 'subTitle'" :min="0" type="number" :placeholder="setType | titleFilter"></el-input>
                  <el-input v-model="setValue" v-else type="text" :placeholder="setType | titleFilter"></el-input>
                  <el-button type="primary" style="margin-left: 10px" @click="setValueFuc">确定</el-button>
                  <el-button @click="closeSetValue">取消</el-button>
                </div>

              </el-form-item>
            </el-col>
          </el-row>
          <el-table :data="results" border :span-method="skuObj.length > 1?objectSpanMethod:''">
            <el-table-column label="商品规格">
              <el-table-column v-for="(item,index) in skuObj" :key="index" :label="item.name" :prop="'spec.sku'+(index+1)+'.value'"/>
            </el-table-column>
            <el-table-column label="图片" prop="imgUrl" width="70">
              <template slot-scope="scope">
                <div v-if="scope.row.imgUrl" class="picItem">
                  <el-image :src="scope.row.imgUrl" style="width: 50px;height: 50px;border-radius: 10px;" />
                </div>
                <el-button v-else icon="el-icon-plus" plain style="width: 50px;height: 50px;border-radius: 10px;padding: 0;display: flex;justify-content: center;align-items: center" @click="showSelecterHandle(scope.$index)"/>
              </template>
            </el-table-column>
            <el-table-column label="SKU" prop="skuId" width="200">
              <template slot-scope="scope">
                <el-input type="text" v-model="scope.row.skuId" placeholder="副标题"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="副标题" prop="subTitle" width="200">
              <template slot-scope="scope">
                  <el-input type="text" v-model="scope.row.subTitle" placeholder="副标题"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="销售价" prop="price" width="100">
              <template slot-scope="scope">
                  <el-input type="number" :min="0" v-model="scope.row.price" placeholder="销售价"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="划线价" prop="marketPrice" width="100">
              <template slot-scope="scope">
                  <el-input type="number" :min="0" v-model="scope.row.marketPrice" placeholder="划线价"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="成本价" prop="cost" width="100">
              <template slot-scope="scope">
                  <el-input type="number" :min="0" v-model="scope.row.cost" placeholder="成本价"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="库存" prop="stock" width="100">
              <template slot-scope="scope">
                 <el-input type="number" :min="0" v-model="scope.row.stock" placeholder="库存"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="库存预警" prop="stockWarning" width="100">
              <template slot-scope="scope">
                <el-input type="number" :min="0" v-model="scope.row.stockWarning" placeholder="库存预警"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="重量(g)" prop="weight" width="100">
              <template slot-scope="scope">
                <el-input type="number" :min="0" v-model="scope.row.weight" placeholder="重量(g)"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="体积(cm³)" prop="volume" width="100">
              <template slot-scope="scope">
                <el-input type="number" :min="0" v-model="scope.row.volume" placeholder="体积(cm³)"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="默认展示" prop="isDefault" width="80">
              <template slot-scope="scope">
                <el-switch :value="scope.row.isDefault" active-value="1" inactive-value="0" @change="switchHandle($event,scope.$index)"></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <fileSelect :visible.sync="showSelecter" title="选择商品图片" state="image" @confirm="showSelecterSubmit"></fileSelect>
    </div>
</template>

<script>
import {apiGetSkuList} from '@/request/api'
import fileSelect  from "@/views/components/fileSelect";
export default {
  name: "skuMore",
  components: {
    fileSelect
  },
  watch: {
    skuObj: {
      handler(val) {
        this.results = [];
        this.result = [];
        this.spanList = [];
        this.doExchange(val,0);
        this.getSpan(val,0)
        this.$emit('change', {skuObj:this.skuObj, goodsSku:this.results});
      },
      deep: true
    },
    goodsType: {
      handler(val) {
        this.getSKUList(val);
        this.skuObj =[]
      }
    }
  },
  filters: {
    titleFilter(val) {
      if(val == 'subTitle'){
        return '请输入副标题';
      }
      if(val == 'price'){
        return '请输入销售价';
      }
      if(val == 'marketPrice'){
        return '请输入划线价';
      }
      if(val == 'cost'){
        return '请输入成本价';
      }
      if(val == 'stock'){
        return '请输入库存';
      }
      if(val == 'stockWarning'){
        return '请输入库存预警';
      }
      if(val == 'weight'){
        return '请输入重量(g)';
      }
      if(val == 'volume'){
        return '请输入体积(cm³)';
      }
    }
  },
  props: {
    goodsType: {
      type: String,
      default: '',
    },
    sku:{
      type: Object,
      default: {}
    }
  },
  created() {
    this.getSKUList()
  },
  data () {
    return {
      setIndex:'',
      showSelecter: false,
      setType: '',
      setValue:'',
      skuObj: this.sku.skuObj,
      skuList:[],
      results:this.sku.sku,
      result:[],
      result1:[],
      spanList:[]
    }
  },
  methods: {
    getSKUList(id) {
      apiGetSkuList({id:id?id:this.goodsType}).then(res => {
        if(res.code == 200) {
          this.skuList = res.data;
        }
      })
    },
    showSelecterSubmit(data) {
      this.showSelecter = false;
      this.results[this.setIndex].imgUrl = data[0].thumb;
    },
    showSelecterHandle(index){
      this.setIndex = index;
      this.showSelecter = true;
    },
    switchHandle(event,index){
      console.log(event,index)
      if(event == '1'){
        this.results.forEach((item,i)=>{
          item.isDefault = '0';
        })
        this.results[index].isDefault = '1';
      }
      if(event == '0'){
        this.results[index].isDefault = '0';
      }
    },
    getSpan(arr,index){
      let count = 0
      arr[index].value.forEach((item,i)=>{
        if(item.isChoosed){
          count ++
        }
      })
      if(index==arr.length-1){
        this.spanList.unshift(count)
        return count
      }else{
        let count1 = this.getSpan(arr,index+1)
        if(index == 0){

        }else{
          this.spanList.unshift(count*count1)
          return count * count1
        }

      }

    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      if(columnIndex < this.spanList.length){
        if(rowIndex % this.spanList[columnIndex] == 0){
          return {
            rowspan: this.spanList[columnIndex],
            colspan: 1
          }
        }else{
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    setValueFuc(){
      this.results.forEach(item=>{
        item[this.setType] = this.setValue;
      })

      this.closeSetValue()
    },
    closeSetValue(){
      this.setType = '';
      this.setValue = '';
    },
    addSku(){
      this.skuObj.push({
        name: '',
        id:'',
        showAdd:false,
        value: []
      })
    },
    skuSelecthandle(id,index){
      var skuItem = JSON.parse(JSON.stringify(this.skuList.find(item=>item.id == id)));
      this.skuObj[index].name = skuItem.label;
      this.skuObj[index].id = skuItem.id;
      this.skuObj[index].showAdd = false;
      skuItem.children.forEach(item=>{
        // item.isChoosed = true;
        item.pId = id
      })
      this.skuObj[index].value = skuItem.children;
    },
    showAdd(index) {
      this.skuObj[index].showAdd = true;
    },
    closeAdd(index) {
      this.skuObj[index].addValue = '';
      this.skuObj[index].showAdd = false;
    },
    addValue(index) {
      if(this.skuObj[index].addValue){
        this.skuObj[index].value.push({
          label: this.skuObj[index].addValue,
          pId:this.skuObj[index].id,
          id:'',
          isChoosed:true
        });
        this.closeAdd(index)
      }
    },
    doExchange(arr, index){
      for (var i = 0; i<arr[index].value.length; i++) {
        if (arr[index].value[i].isChoosed) {
          this.result[index] = arr[index].value[i].label;
          this.result1[index] = {
            name: arr[index].name,
            value: arr[index].value[i].label
          };
          if (index != arr.length - 1) {
            this.doExchange(arr, index + 1)
          } else {
            var obj = {
              subTitle:this.result.join('-'),
              price: 0,
              marketPrice: 0,
              cost: 0,
              weight: 0,
              volume: 0,
              stock: 0,
              stockWarning: 0,
              skuId: this.result.join('-'),
              isDefault:0,
              imgUrl:'',
              spec: {

              },
            };
            this.result1.forEach((item,index) => {
              obj.spec[`sku${index+1}`] = item;
            });
            this.results.push(obj)
          }
        }

      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .delSkuBtn {
    margin-left: .5rem;
    cursor: pointer;
    color: rgba(0,0,0,.5);
  }
  .picItem {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    position: relative;
  }
</style>