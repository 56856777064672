<template>
    <div class="goods-other-info">
      <div style="display: flex;align-items: center;cursor: pointer"  @click="showBox = !showBox">
        <h2 class="goods-other-info-title">其他信息</h2>
        <i style="margin-left: 7px;margin-top: 2px" :class="!showBox?'el-icon-arrow-down':'el-icon-arrow-up'"/>
      </div>
        <div class="box" :style="showBox?'height: 305px;':'height: 0;'">
          <el-form label-width="120px" label-position="left">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="商品单位">
                  <el-input v-model="goodsOtherObj.unit_text" placeholder="请输入商品单位"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="上架">
              <el-radio-group v-model="goodsOtherObj.list_state.type">
                <el-radio :label="'FOLLOW_SYSTEM'">立即上架</el-radio>
                <el-radio :label="'OFF'">放入仓库</el-radio>
                <el-radio :label="'ON'">定时上架</el-radio>
              </el-radio-group>
              <el-date-picker style="margin-left: 10px" v-if="goodsOtherObj.list_state.type == 'ON'" v-model="goodsOtherObj.list_state.timestamp" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择上架时间"/>
            </el-form-item>
            <el-form-item label="定时下架">
              <el-radio-group v-model="goodsOtherObj.take_down.type">
                <el-radio :label="'OFF'">不启用</el-radio>
                <el-radio :label="'ON'">启用</el-radio>
              </el-radio-group>
              <el-date-picker style="margin-left: 10px" v-if="goodsOtherObj.take_down.type == 'ON'" v-model="goodsOtherObj.take_down.timestamp" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择下架时间"/>
            </el-form-item>
            <el-form-item label="会员等级折扣">
              <el-radio-group v-model="goodsOtherObj.level_discount">
                <el-radio :label="0">跟随系统设置</el-radio>
                <el-radio :label="1">不参与</el-radio>
                <el-radio :label="2">参与</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="付费会员折扣">
              <el-radio-group v-model="goodsOtherObj.plus_discount.type">
                <el-radio :label="'FOLLOW_SYSTEM'">跟随系统设置</el-radio>
                <el-radio :label="'OFF'">不参与</el-radio>
                <el-radio :label="'ON'">参与</el-radio>
              </el-radio-group>
              <div style="display: inline-flex;align-items: center;margin-left: 10px" v-if="goodsOtherObj.plus_discount.type == 'ON'">
                <span>付费会员最终再享</span>
                <el-input-number :min="1" :max="100" v-model="goodsOtherObj.plus_discount.discount"/>
                <span>%折扣</span>
              </div>
            </el-form-item>
            <el-form-item label="虚拟销量">
              <el-radio-group v-model="isVirtualSales">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
              <el-input-number style="margin-left: 10px" v-if="isVirtualSales == 1" :min="1" v-model="goodsOtherObj.vir_sold"/>
            </el-form-item>
          </el-form>
        </div>

    </div>
</template>

<script>
export default {
  name: "goodsOtherInfo",
  watch:{
    goodsOtherObj: {
      handler(val) {
        this.$emit('handleChange', val)
      },
      deep: true
    },
    isVirtualSales: {
      handler(val) {
        if(val){
          this.goodsOtherObj.vir_sold = 1
        }else{
          this.goodsOtherObj.vir_sold = 0
        }
      },
      deep: true
    }
  },
  props:{
    goodsOtherObj: {
      type: Object,
      default: {
        unit_text: '件',
        list_state:{
          type: 'FOLLOW_SYSTEM',
          timestamp:''
        },
        take_down:{
          type: 'OFF',
          timestamp:''
        },
        plus_discount:{
          type: 'FOLLOW_SYSTEM',
          discount: 0
        },
        level_discount:0,
        vir_sold : 0,
      }
    }
  },
  data() {
    return {
      showBox:false,
      isVirtualSales: this.goodsOtherObj.vir_sold>=1 ? 1 : 0,
    }
  },
}
</script>

<style scoped>
.goods-other-info {
  width: 100%;
  padding-top: 0.6rem;
}
.el-form-item{
  margin-top: 10px;
}
.box {
  overflow: hidden;
  transform-origin: top;
  transition: all 0.5s;
}
</style>