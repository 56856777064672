<template>
    <div class="goods-optimization">
      <h2>优化设置</h2>
      <el-form label-width="120px" label-position="left">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="详细页标题">
              <el-input v-model="goodsOptimization.seo_title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="详细页描述">
              <el-input v-model="goodsOptimization.seo_keyword"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商品备注">
              <el-input type="textarea" :rows="5" v-model="goodsOptimization.seo_descr"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
</template>

<script>
export default {
  name: "goodsOptimization",
  props: {
    goodsOptimization: {
      type: Object,
      default: {
        seo_title: '',
        seo_keyword: '',
        seo_descr: '',
      }
    },
    watch: {
      goodsOptimzation: {
        handler(val) {
          this.$emit('handleChange', this.goodsOptimization)
        },
        deep: true
      }
    },
    data() {
      return {}
    },
  }
}
</script>

<style scoped>

</style>