<template>
  <div style="position: relative;width: 100%;height: 100%">
    <div class="page">
      <el-tabs v-model="tabIndex" @tab-click="changeTab">
        <el-tab-pane label="基本设置" name="first">
          <goodsBaseInfo :goods="goodsBaseInfo" @handleChange="goodsBaseInfoHandle"/>
          <sku :goodsSku="sku" :goodsType="goodsBaseInfo.goods_type" @handleChange="skuHandle"/>
          <logistic :logistic="shipping_policy" @handleChange="logisticHandle"/>
          <purchaseLimits :limitObj="purchase_area_limit" @handleChange="purchaseLimitsHandle"/>
          <goodsOtherInfo :goodsOtherObj="goodsOtherInfo" @handleChange="goodsOtherInfoHandle"/>
          <div class="fucView" >
              <el-button @click="save" type="primary">保存</el-button>
              <el-button @click="settabIndex('second')">下一页</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="商品详情" name="second">
          <goodsDetail :goodsDetail="goodsDetail" :goodsType="goodsBaseInfo.goods_type" @handleChange="goodsDetailHandle"/>
          <div class="fucView">
            <el-button @click="save" type="primary">保存</el-button>
            <el-button @click="settabIndex('first')">上一页</el-button>
            <el-button @click="settabIndex('third')">下一页</el-button>
          </div>


        </el-tab-pane>
        <el-tab-pane label="优化设置" name="third">
          <goodsOptimization :goodsOptimization="goodsSEO" @handleChange="goodsOptimizationHandle"/>
          <div class="fucView">
            <el-button @click="save" type="primary">保存</el-button>
            <el-button @click="settabIndex('second')">上一页</el-button>
            <el-button @click="settabIndex('fourth')">下一页</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="营销设置" name="fourth">
          <goodsMarketing @handleChange="goodsMarketingHandle"/>
          <div class="fucView">
            <el-button @click="settabIndex('third')">上一页</el-button>
          </div>
        </el-tab-pane>

      </el-tabs>
    </div>

  </div>

</template>

<script>
import goodsBaseInfo  from "@/views/goods/components/goodsBaseInfo";
import logistic   from "@/views/goods/components/logistic";
import purchaseLimits   from "@/views/goods/components/purchaseLimits";
import goodsOtherInfo   from "@/views/goods/components/goodsOtherInfo";
import goodsDetail  from "@/views/goods/components/goodsDetail";
import goodsMarketing   from "@/views/goods/components/goodsMarketing";
import goodsOptimization   from "@/views/goods/components/goodsOptimization";
import sku from "@/components/sku";
import {apiSaveGoods,apiGetGoodsInfo} from "@/request/api";
export default {
  name: "editGoods",
  components: {
    goodsBaseInfo,
    logistic,
    purchaseLimits,
    goodsOtherInfo,
    goodsDetail,
    goodsMarketing,
    goodsOptimization,
    sku
  },
  data(){
    return{
      tabIndex: 'first',
      goodsId: this.$route.query.goodsId,
      goodsBaseInfo:{
        name: "",
        slug: "",
        promotion: "",
        tag: [],
        supply_id: 0,
        category_id: "",
        shop_category_id: 0,
        brand_id: "",
        gallery: [],
        video: [],
        goods_type:""
      },
      shipping_policy:{
        price: 0,
        template_id: '',
        type: 'FOLLOW_SYSTEM'
      },
      purchase_area_limit:{
        type: 'FOLLOW_SYSTEM',
        area_id: [],
        purchase_num_limit: 0,
        purchase_min_limit: 1
      },
      goodsOtherInfo:{
        unit_text: '件',
        list_state:{
          type: 'FOLLOW_SYSTEM',
          timestamp:''
        },
        take_down:{
          type: 'OFF',
          timestamp:''
        },
        plus_discount:{
          type: 'FOLLOW_SYSTEM',
          discount: 0
        },
        level_discount:0,
        vir_sold : 0,
      },
      goodsDetail:{
        param:[],
        descr:''
      },
      goodsMarketing:{},
      goodsSEO:{
        seo_title:'',
        seo_keyword:'',
        seo_descr:'',
      },
      sku:{
        type: "SINGAL_PRICE",
        sku:[
          {
            cost:0,
            price:0,
            stock:0,
            volume:'',
            weight:0,
            marketPrice:0,
            stockWarning:0,
            skuId:''
          }
        ],
        skuObj:[]
      }
    }
  },
  created() {
    this.getGoodsInfo();
  },
  methods:{
    settabIndex(index){
      console.log(index);
      this.tabIndex = index;
    },
    getGoodsInfo(){
      apiGetGoodsInfo({id:this.goodsId}).then(res => {
        this.goodsBaseInfo = res.data.goodsBaseInfo;
        this.shipping_policy = res.data.shipping_policy;
        this.purchase_area_limit = res.data.purchase_area_limit;
        this.purchase_area_limit.purchase_min_limit=res.data.purchase_min_limit
        this.purchase_area_limit.purchase_num_limit=res.data.purchase_num_limit
        this.goodsOtherInfo = res.data.goodsOtherInfo;
        this.goodsDetail = res.data.goodsDetail;
        // this.goodsMarketing = res.data.goodsMarketing;
        this.goodsSEO = res.data.goodsSEO;
        this.sku = res.data.sku;
      })
    },
    save(){
      var obj = {
        id:this.goodsId,
        goodsBaseInfo:this.goodsBaseInfo,
        shipping_policy:this.shipping_policy,
        purchase_area_limit:this.purchase_area_limit,
        purchase_min_limit:this.purchase_area_limit.purchase_min_limit,
        purchase_num_limit:this.purchase_area_limit.purchase_num_limit,
        goodsOtherInfo:this.goodsOtherInfo,
        goodsDetail:this.goodsDetail,
        // goodsMarketing:this.goodsMarketing,
        goodsSEO:this.goodsSEO,
        sku:this.sku
      }
     delete obj.purchase_area_limit.purchase_min_limit;
     delete obj.purchase_area_limit.purchase_num_limit;
      // console.log(JSON.stringify())
      apiSaveGoods(obj).then(res=>{
        console.log(res);
      })
    },
    changeTab(tab,event){
      console.log(tab.name);
      this.nowtabIndex = tab.name;
    },
    goodsBaseInfoHandle(data){
      this.goodsBaseInfo=data
    },
    logisticHandle(data){
      this.shipping_policy=data
    },
    purchaseLimitsHandle(data){
      this. purchase_area_limit=data
    },
    goodsOtherInfoHandle(data){
      this.goodsOtherInfo=data
    },
    goodsDetailHandle(data){
      this.goodsDetail=data
    },
    goodsMarketingHandle(data){
      this.goodsMarketing=data
    },
    goodsOptimizationHandle(data){
      this.goodsSEO=data
    },
    skuHandle(data){
      this.sku=data
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  margin-bottom: 60px;
  //margin: 20px;
  //margin-bottom: 80px;
}
.fucView {
  height: 60px;
  width: 100%;
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 220;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
</style>