<template>
  <div class="goodsMarketing">
    <h2>营销设置</h2>
    <el-form label-width="120px" label-position="left">
      <el-form-item v-for="(item,index) in goodsMarketing.marketingList" :key="index">
        <span>{{item}}</span>
        <div class="marketingFuc">
          <el-button>新建活动</el-button>
          <el-button>添加到活动</el-button>
        </div>
<!--        <el-table></el-table>-->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "goodsMarketing",
  data (){
    return {
      goodsMarketing:{
        marketingList:[]
      }
    }
  }
}
</script>

<style scoped>

</style>