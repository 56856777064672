<template>
  <div>
    <div class="dragView" >
          <div v-for="(item,index) in videoList" :key="item.url" class="picItem">
            <el-button class="delBtn" type="danger" icon="el-icon-delete" size="mini" circle @click="delVideo"/>
            <video style="width: 100px; height: 100px;border-radius: 10px" :src="item.url" controls></video>
          </div>

      <el-button v-if="videoList.length == 0" icon="el-icon-plus" plain style="width: 100px; height: 100px;border-radius: 10px" @click="showSelecter = true"/>

    </div>
    <fileSelect :visible.sync="showSelecter" title="选择商品视频" state="video" multiple="false" @confirm="showSelecterSubmit"></fileSelect>
  </div>


</template>

<script>
import fileSelect  from "@/views/components/fileSelect";
export default {
  name: "dragableVideoList",
  data() {
    return {
      showSelecter: false,
    }
  },
  components: {
    fileSelect
  },
  props: {
    videoList: {
      type: Array,
      default: []
    }
  },
  watch: {
    videoList: {
      handler: function (newVal, oldVal) {
        this.$emit('change', this.videoList)
      },
      deep: true
    }
  },
  methods: {
    showSelecterSubmit(data) {
      this.videoList = [...this.videoList,...data]
      this.showSelecter = false
    },
    delVideo(){
      this.videoList=[]
    }
  }
}
</script>

<style lang="scss" scoped>
.dragView {
  height: 120px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .picItem {
    width: 100px;
    height: 100px;
    margin: 5px;
    border-radius: 10px;
    position: relative;
    .delBtn {
      display: none;
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 20;
    }
    .setFirst {
      display: none;
      position: absolute;
      top: -10px;
      right: 20px;
      z-index: 20;
    }
    .firstLabel {
      position: absolute;
      font-size: 24px;
      color: #006eff;
      top: -8px;
      left: -8px;
      z-index: 20;
    }
  }
  .picItem:hover {
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.25);
    .delBtn,.setFirst {
      display: block;
    }
  }
}
</style>